<template>

<div class="foot" :class="{'is-home': isLanding}">

	<div class="foot-inner">

		<div class="foot-cta">

			<router-link :to="$link(foot.donate.url)" class="foot-cta-button is-donate" v-if="!isLanding">

				<div class="foot-cta-button-icon" />

				<div class="foot-cta-button-info">
					<div class="foot-cta-button-info-title">{{ foot.donate.title }}</div>
				</div>

			</router-link>

			<router-link :to="$link(foot.subscribe.url)" class="foot-cta-button is-subscribe" v-if="!isLanding">

				<div class="foot-cta-button-icon" />

				<div class="foot-cta-button-info">
					<div class="foot-cta-button-info-title">{{ foot.subscribe.title }}</div>
				</div>

			</router-link>

		</div>

		<div class="foot-wrapper">

			<div class="foot-info">

				<div class="foot-copyright">{{ foot.copyright }}</div>

				<div class="foot-links" v-if="foot.links.length">
					<router-link :to="$link(item.url)" class="foot-links-item" v-for="(page, index) in foot.links" :key="index">{{ page.name }}</router-link>
				</div>

				<div class="foot-text">{{ foot.text }}</div>

			</div>

			<div class="foot-social">

				<a :href="link.url" target="_blank" v-for="(link, index) in foot.social" :key="index" class="foot-social-item" :data-type="link.type"><app-icon :s="link.type" /><span>{{ link.text }}</span></a>

			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['foot', 'isLanding']

}

</script>

<style scoped>

.foot {
	background-color: #1A234C;
}

.foot-inner {
	margin: 66px auto 0px auto;
	max-width: 1120px;
	padding: 0px 10px 40px 10px;
}

.foot.is-home .foot-inner {
	margin-top: 0px;
}

.foot-cta {
	display: flex;
	flex-direction: row;
	max-width: 908px;
	top: -66px;
	margin: 0px auto;
	justify-content: space-between;
}

.is-mobile .foot-cta {
	flex-direction: column;
	justify-content: flex-start;
}

.foot-cta-button {
	border-radius: 66px;
	min-height: 132px;
	padding: 20px;
	width: calc(50% - 20px);
	display: flex;
	align-items: center;
}

.foot-cta-button.is-subscribe {
	background-color: #1277D4;
}

.foot-cta-button.is-donate {
	background-color: #C21920;
}

.is-mobile .foot-cta-button {
	border-radius: 0px;
	min-height: auto;
	top: auto;
	width: calc(100% + 20px);
	margin-left: -10px;
	padding: 10px;
}

.foot-cta-button-icon {
	width: 90px;
	height: 90px;
	border-radius: 45px;
	flex-shrink: 0;
	margin-right: 40px;
	background-image: url(~@/assets/news.png);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.is-subscribe .foot-cta-button-icon {
	background-color: #1A234C;
	background-image: url(~@/assets/news.png);
	background-size: auto 48px;
}

.is-mobile .is-subscribe .foot-cta-button-icon {
	background-size: auto 32px;
}

.is-donate .foot-cta-button-icon {
	background-image: url(~@/assets/donate.png);
	background-size: contain;
}

.is-mobile .foot-cta-button-icon {
	width: 62px;
	height: 62px;
}

.foot-cta-button-chevron {
	width: 58px;
	height: 58px;
	border-radius: 29px;
	border: 3px solid #fff;
	flex-shrink: 0;
	color: #fff;
	font-size: 24px;
	text-align: center;
	line-height: 52px;
}

.foot-cta-button-info {
	flex-grow: 1;
}

.foot-cta-button-info-title {
	color: #fff;
	text-transform: uppercase;
	font-size: 24px;
}

.is-device .foot-cta-button-info-title {
	font-size: 20px;
}

.is-mobile .foot-cta-button-info-title {
	font-size: 16px;
}

.foot-cta-button-info-text {
	font-size: 16px;
	color: #fff;
	margin-top: 10px;
	line-height: 20px;
}

.is-mobile .foot-cta-button-info-text {
	font-size: 14px;
	line-height: 20px;
	padding-right: 10px;
}

.foot-wrapper {
	display: flex;
	padding-top: 40px;
}

.is-device .foot-wrapper {
	flex-direction: column;
	padding-top: 0px;
	align-items: center;
}

.foot-social {
	flex-shrink: 0;
	display: flex
}

.is-device .foot-social {
	margin-top: 20px;
}

.foot-social-item {
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	font-size: 24px;
	text-align: center;
}

.foot-social-item span {
	display: none;
}

.foot-info {
	flex-grow: 1;
	margin-right: 40px;
}

.is-device .foot-info {
	margin-right: 0px;
	padding-top: 40px
}

.foot-copyright {
	font-size: 16px;
	margin-bottom: 10px;
	color: #fff;
}

.is-device .foot-copyright {
	text-align: center;
}

.foot-links {
	display: flex;
	flex-wrap: wrap;
	color: #fff;
	margin-bottom: 10px;
}

.is-device .foot-links {
	justify-content: center;
}

.foot-links-item {
	font-size: 14px;
	border-right: 1px solid #fff;
	padding: 0px 10px;
}

.foot-links-item:hover {
	text-decoration: underline;
}

.foot-links-item:first-child {
	padding-left: 0px;
}

.foot-links-item:last-child {
	border-right: 0px;
	padding-right: 0px;
}

.foot-text {
	font-size: 12px;
	line-height: 16px;
	color: #fff;
}

.is-device .foot-text {
	text-align: center;
}

</style>
